import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import MyNavLink from "@/components/MyNavLink";
import UserLogShow from "@/components/UserLogShow";
import { LocalConfig } from "@/config";
import { PersistContext } from "@/data/PersistProvider";
import { LanguageContext } from "@/data/LanguageProvider";
import { useEnhancedHistory } from "@/data/RouterProvider";
import { SendCMD } from "@/utils/HTTPRequest";
import { ChannelRemind } from "@/utils/UrlHelper";
import { useContext, useEffect, useState } from "react";
import { DropdownMenu, List, Pagination } from "react-vant";
import style from "./style.less";

const WithdrawLog = () => {
  const { getPersist } = useContext(PersistContext);
  const { langText } = useContext(LanguageContext);
  const history = useEnhancedHistory();

  const [list, setList] = useState(1);

  //设置是否已加载完成
  const [finished, setFinished] = useState(false);

  //设置页码数
  const [pageCount, setPageCount] = useState(false);
  const [page, setPage] = useState(1);

  //设置选择条件
  const [rangeType, setRangeType] = useState(false);

  //返回数据
  const [dataLog, setDataLog] = useState([]);
  const [dataLogLength, setDataLogLength] = useState(0);

  // 累计提现
  const [totalWithdraw, setTotalWithdraw] = useState(0);

  useEffect(() => {
    if (!getPersist("dataLoaded")) {
      return;
    }
    if (!getPersist("user_info")) {
      history.push(ChannelRemind("/login"));
      return;
    }
    getPayOrders(page);
  }, [getPersist("dataLoaded")]);

  const [logSearchBg, setLogSearchBg] = useState("#10473f");
  const setParentBackground = (type) => {
    if (type === "open") {
      setLogSearchBg("#2B685F");
    } else if (type === "close") {
      setLogSearchBg("#10473f");
    }
  };

  //获取记录
  const getPayOrders = (page) => {
    SendCMD("payOrders", {
      token: getPersist("user_info").token,
      order_type: "withdraw",
      range_type: rangeType.selectValue,
      page: page,
    }).then((res) => {
      setTotalWithdraw(res.withdraw_total);
      if (res.rows.length > 0) {
        let option = [];
        res.rows.map((value, index) => {
          let statusText = value.status;
          if (value.status === "INIT") {
            statusText = "REVIEWING";
          } else if (value.status === "FAILD") {
            statusText = "FAILED";
          }
          option.push({ time: value.create_time, amount: value.price / 100, status: statusText });
        });
        setDataLog(option);
        setDataLogLength(option.length);
      }
    });
  };

  //加载
  const onLoad = async () => {
    setList((v) => [...v, ...dataLog]);
    if (list.length >= 30) {
      setFinished(true);
    }
  };

  const optionRangeType = [
    {
      value: "today",
      text: langText("withdraw_title_9"),
    },
    {
      value: "3days",
      text: langText("withdraw_title_10"),
    },
    {
      value: "7days",
      text: langText("withdraw_title_11"),
    },
    {
      value: "30days",
      text: langText("withdraw_title_12"),
    },
  ];

  const onDropdownMenuChange = (value) => {
    setRangeType(value);
    setPage(1);
  };

  const onPaginationChange = (value) => {
    setPage(value);
    getPayOrders(value);
  };
  return (
    <>
      <div class={style.page_container}>
        <HomeHeaderOther title={langText("withdraw_title_7")} backToUrl={-1} />
        <div class={style.help_content_log}>
          <div className={style.withdraw_balance_record}>
            <span>{langText("withdraw_title_8")} </span>
            <span className={style.last}>
              {(totalWithdraw / 100).toLocaleString("en-US", { maximumFractionDigits: 2 })} {LocalConfig.currencyCode}
            </span>
          </div>
          <div class={style.log}>
            <div class={style.log_search} style={{ backgroundColor: logSearchBg }}>
              <DropdownMenu
                activeColor=""
                value={rangeType}
                onChange={onDropdownMenuChange}
                onClosed={getPayOrders}
                onOpen={() => setParentBackground("open")}
                onClose={() => setParentBackground("close")}
                style={{
                  "--rv-dropdown-menu-title-text-color": "#055d27",
                  "--rv-button-primary-border-color": "#055d27",
                  "--rv-tab-active-text-color": "#ffffff",
                  "--rv-dropdown-menu-background-color": "#A7D54E",
                  "--rv-cell-background-color": "#2B685F",
                  "--rv-cell-text-color": "#ffffff",
                  "--rv-cell-border-color": "#44857B",
                  "--rv-dropdown-menu-title-font-size": "1.5rem",
                  "--rv-dropdown-menu-title-line-height": "1.8rem",
                  "--rv-cell-line-height": "1.53rem",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                <DropdownMenu.Item placeholder={langText("withdraw_title_9")} name="selectValue" options={optionRangeType} />
              </DropdownMenu>
            </div>
            <div class={style.log_intro}>
              {langText("withdraw_tips_18_1")}{" "}
              <MyNavLink to="/contact">
                <span>{langText("withdraw_tips_18_2")}</span>
              </MyNavLink>{" "}
              {langText("withdraw_tips_19", { param1: getPersist("user_info") ? getPersist("user_info").rid : "" })}
            </div>
            <div class={style.log_list}>
              <div class={style.info}>
                <div class={style.info_time}>{langText("withdraw_title_13")}</div>
                <div class={style.info_amount}>{langText("withdraw_title_14", { param1: LocalConfig.currencyCode })}</div>
                <div class={style.info_status}>{langText("withdraw_title_15")}</div>
              </div>
              <List finished={finished} onLoad={onLoad}>
                {dataLog && dataLog.length ? (
                  dataLog.map((v, i) => {
                    return <UserLogShow k={i} info={v} length={dataLogLength} />;
                  })
                ) : (
                  <div class={style.log_list_no}>{langText("withdraw_tips_20")}</div>
                )}
              </List>
              <div class={style.log_page}>
                <Pagination
                  prevText={langText("withdraw_btn_3")}
                  nextText={langText("withdraw_btn_4")}
                  value={page}
                  mode="simple"
                  onChange={onPaginationChange}
                  pageCount={pageCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawLog;
